import { API } from "../../api";

const getAuth = async () => {
  try {
    const response = await API.get("user", "check-auth");
    return response.status === 200
  } catch (error) {
    console.error('Error checking authentication', error);
    return false;
  }
};

const getAdminAuth = async () => {
  try {
    const response = await API.get("user", "admin-auth");
    console.log({response})
    return response.status === 200
  } catch (error) {
    console.error('Error checking authentication', error);
    return false;
  }
};

export { getAuth, getAdminAuth };
